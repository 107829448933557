import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios"
import { useLocation } from 'react-router-dom';

//console.log("I'm here ViewStoreDetailComponent.js");

const ViewStoreDetailComponent= () => {
    //const [storeId, setStoreId] = useState([]);

    const [data, setData] = useState({ stores: [], items: [] });
    const location = useLocation();
    const storeCity = location.state?.storeCity;
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Received storeCity: ", storeCity);

        const fetchStoreIdandsetStoreId = async () => {
            try {
                const result = await axios.get(`https://walmart.machimpex.com/api/searchcity/${storeCity}`);
                setData(result.data);
                console.log("API Result", result.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        if (storeCity) {
            fetchStoreIdandsetStoreId();
        }
    }, [storeCity]); // Dependency on storeNumber to refetch if it changes

    const getStoreTypeDisplayValue = (storeType) => {
        switch (storeType) {
            case 'BASE STR Nghbrhd Mkt':
                return 'Nghbrhd Mkt';
            case 'BASE STR Supercenter':
                return 'Supercenter';
            case 'BASE STR Wal-Mart':
                return 'Regular Store';
            default:
                return storeType; // Default to the original value if no match is found
        }
    };



    

  return (

      <div>
          <div className="fixed-store-info">
              <div className="table-responsive" style={{ marginTop: '5px' }}>
                  <button onClick={() => navigate(-1)} className="btn btn-primary btn-sm btn-block mb-1">
                      Back to Main Page
                  </button>
              </div>
          </div>

          <div className="item-detail-information">
              <h3>Item Detail</h3>
              <div className="table-responsive">
                  <table className="table table-striped table-sm">
                      <thead className="table-dark">
                          <tr>
                              <th align="right">Desciption</th>
                              <th>Value</th>
                          </tr>
                      </thead>
                      <tbody>


                          {data.stores.map((store, idx) => (
                              <tr key={`${store.store_number}-${idx}`} onClick={() => navigate('/viewitem', { state : { storeNumber: store.store_number} })} style={{ cursor: 'pointer' }}>
                                  <td key={`label-${store.store_number}-${idx}`} align="right" style={{ lineHeight: "1.0" }}>
                                      <b>
                                          Store #:<br />
                                          Store Name:<br />
                                          Address:<br />
                                          City, State, Zip:<br />
                                          Store Phone:<br />
                                          Store Type:<br />
                                          Warehouse:<br />
                                          IDC: <br />
                                          IDC Description:<br />
                                      </b>
                                  </td>
                                  <td className="fs-6" key={`content-${store.store_number}-${idx}`} style={{ lineHeight: "1.0" }}>
                                      {store.store_number}<br />
                                      {store.store_name}<br />
                                      {store.store_addr}<br />
                                      {store.store_city}, {store.store_state} {store.store_postal}<br />
                                      {store.store_phone}<br />
                                      {getStoreTypeDisplayValue(store.store_type)}<br />
                                      {store.store_reg_whse}<br />
                                      {store.store_idc}<br />
                                      {store.store_idc_descr}<br />
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
          </div>
      </div>


  );
}

export default ViewStoreDetailComponent;