import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
//import axios from "axios";
//import { customAlphabet } from 'nanoid';


const SelectStoreComponent = () => {
    //const [storeId, setStoreId] = useState([]);
    const [storeNbrUI, setStoreNbrUI] = useState("");
    const [storeCityUI, setCityUI] = useState("");
    
    // get geolocation (if possible)
    const [city, setCity] = useState("");

    const navigate = useNavigate();

    // Trigger the store lookup
    const handleSubmit = (event) => {
        event.preventDefault();

        // Redirect to the ViewItemComponent and pass the store number
        navigate('/viewitem', { state: { storeNumber: storeNbrUI } });
    };
    
    // Trigger the city lookup
    const handleSubmitCity = (event) => {
        event.preventDefault();

        // Redirect to the ViewItemComponent and pass the store number
        navigate('/viewstores', { state: { storeCity: storeCityUI } });
    };

    // Trigger a geo-lookup to get local city name
    const handleGetCurrentLocation = (event) => {
        event.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
                const data = await response.json();
                setCityUI(data.address.city || 'City not found');
            }, (error) => {
                console.error(error);
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };
  
  return (
      <div>
          <main>
              <section className="container text-center d-flex flex-column justify-content-center align-items-center">
                  <h3 className="display-2 mb-4">Traited Store Item Lookup</h3>
                  <form className="row" onSubmit={handleSubmit}>
                      <div className="col-12 col-mt-6 mb-4">
                          <label htmlFor="storeNumber" className="form-label mb-4 fs-3 fs-md-2">Store Number</label>
                          <input
                              id="storeNumber"
                              className="form-control p-1 mb-1 fs-5 fs-md-6"
                              type="text"
                              pattern="\d*"
                              placeholder="Enter Walmart Store #"
                              value={storeNbrUI}
                              onChange={e => setStoreNbrUI(e.target.value)}
                          />
                      </div>
                      <div className="col-12 col-mt-3">
                          <div className="d-grid gap-2 col-9 mx-auto">
                              <button type="submit" className="btn btn-primary fs-5 fs-md-6">
                                  Show Traited Items
                              </button>
                          </div>
                      </div>
                  </form>
                  <hr width="90%"></hr>
                  <form className="row" onSubmit={handleSubmitCity}>
                      <div className="col-12 col-mt-6 mb-4">
                          <label htmlFor="storeCity" className="form-label mb-4 fs-3 fs-md-2">Store Search by City</label>
                          <input
                              id="storeCity"
                              className="form-control p-1 mb-1 fs-5 fs-md-6"
                              type="text"
                              placeholder="City to search"
                              value={storeCityUI}
                              onChange={e => setCityUI(e.target.value)}
                          />
                      </div>
                      <div className="col-12 col-mt-3">
                          <div className="d-grid gap-2 col-9 mx-auto">
                              <button type="submit" className="btn btn-secondary fs-5 fs-md-6">
                                  Search for City
                              </button>
                              <a href="#" onClick={handleGetCurrentLocation} style={{ cursor: 'pointer', textDecoration: 'underline', fontSize: '14px', color: 'grey' }}>
                                Get Current Location
                              </a>
                          </div>
                      </div>
                  </form>
              </section>
          </main>
      </div>

  );
}

export default SelectStoreComponent;