import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import { useLocation } from 'react-router-dom';
// import { URLSearchParams}  from 'url-search-params';



const ViewItemDetailComponent = () => {
    const [data, setData] = useState({ items: [] });
    const location = useLocation();
    //const itemNumber = location.state?.itemNumber;
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const isItemActive = JSON.parse(queryParams.get('isActive'));


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const itemValue = queryParams.get('item');

        const fetchItemDetail = async () => {
            try {
                const result = await axios.get(`https://walmart.machimpex.com/api/itemdetail/${itemValue}`);
                setData(result.data);
                console.log(result.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        if (itemValue) {
            fetchItemDetail();
        }
    }, [location.search]); // Dependency on storeNumber to refetch if it changes


    const getIsStoreActive = (isItemActive) => {
        /*
        if (isItemActive === false) {
            return 'INACTIVE'
        } else {
            return 'Active';
        }
        */
        return isItemActive ? 'Active' : 'INACTIVE';
    };

    const getIsStoreActiveStyle = (isItemActive) => {
        return isItemActive ? {} : { color: 'red' };
    };

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <div className="fixed-store-info">
                <div className="table-responsive" style={{ marginTop: '5px' }}>
                    <button onClick={() => navigate(-1)} className="btn btn-primary btn-sm btn-block mb-1">
                        Back to Item List
                    </button>
                </div>
            </div>

            <div className="item-detail-information">
                <h3>Item Detail</h3>
                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead className="table-dark">
                            <tr>
                                <th align="right">Desciption</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.items.map((item, idx) => (
                                <tr key={`${item.prime_nbr}-${idx}`}>
                                    <td key={`label-${item.prime_nbr}-${idx}`} align="right" style={{ lineHeight: "1.0" }}>
                                        <b>
                                            Dept #:<br />
                                            Prime #:<br />
                                            Descrip:<br />
                                            UPC #:<br />
                                            Fineline:<br />
                                            Descrip:<br />
                                            Vndr Stk:<br />
                                            VN/WH PK:<br />
                                            Store Active:<br />
                                        </b>
                                    </td>
                                    <td className="fs-6" key={`content-${item.prime_nbr}-${idx}`} style={{ ...getIsStoreActiveStyle(isItemActive), lineHeight: "1.0" }}>
                                        {item.dept}<br />
                                        {item.prime_nbr}<br />
                                        {item.prime_descr}<br />
                                        {item.upc}<br />
                                        {item.fineline}<br />
                                        {item.fineline_descr}<br />
                                        {item.vndr_stock}<br />
                                        {item.vnpk} / {item.whpk}<br />
                                        {getIsStoreActive(isItemActive)}<br />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                {data.items.map((item, idx) => (
                    <center><img src={`./images/items/${item.prime_nbr}.jpg`} class="img-fluid" alt={`Image of item #${item.prime_nbr}`}></img></center>
                ))}
                </div>
            </div>
            <span className="mx-auto"><center>
                <button className="btn btn-block btn-secondary btn-sm mt-1 m-4 mx-auto" onClick={() => navigate(-1)}>Back to Store Item List</button>
            </center>
            </span>
        </div>
    );
}

export default ViewItemDetailComponent;
