import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios"
import { useLocation } from 'react-router-dom';



const ViewItemComponent = () => {
    const [data, setData] = useState({ stores: [], items: [] });
    const location = useLocation();
    const storeNumber = location.state?.storeNumber;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStoreIdandsetStoreId = async () => {
            try {
                const result = await axios.get(`https://walmart.machimpex.com/api/items/${storeNumber}`);
                setData(result.data);
                console.log(result.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        if (storeNumber) {
            fetchStoreIdandsetStoreId();
        }
    }, [storeNumber]); // Dependency on storeNumber to refetch if it changes





    const getStoreTypeDisplayValue = (storeType) => {
        switch (storeType) {
            case 'BASE STR Nghbrhd Mkt':
                return 'Nghbrhd Mkt';
            case 'BASE STR Supercenter':
                return 'Supercenter';
            case 'BASE STR Wal-Mart':
                return 'Regular Store';
            default:
                return storeType; // Default to the original value if no match is found
        }
    };

    const getIsStoreActive = (isStoreActive) => {
        return isStoreActive ? 'Active' : 'INACTIVE';
    };


    const getIsStoreActiveStyle = (isStoreActive) => {
        return isStoreActive ? {} : { color: 'red' };
    };

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <div className="fixed-store-info">
                <div className="table-responsive" style={{ marginTop: '5px' }}>
                    <button onClick={() => navigate(-1)} className="btn btn-primary btn-sm btn-block mb-1">
                        Enter New Store
                    </button>
                    <table className="table">
                        <thead className="table-dark">
                            <tr>
                                <th width="25%">Store #</th>
                                <th width="25%">Store Type</th>
                                <th width="25%">WHSE #</th>
                                <th width="25%">IDC #</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.stores.map((store, idx) => (
                                <tr key={idx}>
                                    <td>#{store.store_number} - {store.store_name}</td>
                                    <td>{getStoreTypeDisplayValue(store.store_type)}</td>
                                    <td>{store.store_reg_whse}</td>
                                    <td>{store.store_idc} - {store.store_idc_descr}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="item-information">
                <h3>Item Information</h3>
                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead className="table-dark">
                            <tr>
                                <th align="right">Items</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.items.map((item, idx) => (
                                <tr key={`${item.prime_nbr}-${idx}`} >
                                    <td className="fs-6" key={`content-${item.prime_nbr}-${idx}`} style={{ ...getIsStoreActiveStyle(item.isStoreActive), lineHeight: "1.0" }}>
                                        <Link
                                            to={{
                                                pathname: '/viewitemdetail',
                                                search: `?item=${item.prime_nbr}&isActive=${item.isStoreActive}`,
                                                state: { itemNumber: item.prime_nbr }
                                            }}
                                            className="custom-link"
                                            style={getIsStoreActiveStyle(item.isStoreActive)}
                                        >
                                            <b>{item.dept}</b> - {item.prime_descr} ({item.prime_nbr})
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <span className="mx-auto"><center>
                <button className="btn btn-block btn-secondary btn-sm mt-1 m-4 mx-auto" onClick={handleBackToTop}>Back to Top</button>
            </center>
            </span>
        </div>

    );
}

export default ViewItemComponent;