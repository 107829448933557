import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import ViewItemComponent from "./components/ViewItemComponent";
import ViewItemDetailComponent from "./components/ViewItemDetailComponent";
import SelectStoreComponent from "./components/SelectStoreComponent";
import ViewStoreDetailComponent from "./components/ViewStoreDetailComponent";

function App() {
  return (
    <div className="App container mt-5">
      <Router>
        <Routes>
          <Route path="/" element={<SelectStoreComponent />} exact />
          <Route path="/viewitem" element={<ViewItemComponent />} />
          <Route path="/viewitemdetail" element={<ViewItemDetailComponent />} />
          <Route path="/viewstores" element={<ViewStoreDetailComponent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;